import _getFileNameAndExtension from "./getFileNameAndExtension";
import _mimeTypes from "./mimeTypes";
var exports = {};
var getFileNameAndExtension = _getFileNameAndExtension;
var mimeTypes = _mimeTypes;

exports = function getFileType(file) {
  var fileExtension = file.name ? getFileNameAndExtension(file.name).extension : null;
  fileExtension = fileExtension ? fileExtension.toLowerCase() : null;

  if (file.type) {
    // if mime type is set in the file object already, use that
    return file.type;
  }

  if (fileExtension && mimeTypes[fileExtension]) {
    // else, see if we can map extension to a mime type
    return mimeTypes[fileExtension];
  } // if all fails, fall back to a generic byte stream type


  return "application/octet-stream";
};

export default exports;